import React, { Component } from "react";
import { Row, Container, Col } from "reactstrap";

const DataSecurityAndPrivacy = (props) => {
  return (
    <React.Fragment>
      <Container>
        <Row className="api-services-content">
          <Col lg={12} md={12} sm={12} xs={12}>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <h4>
                  <b className="color-grey">Data Security and Privacy</b>
                </h4>
                <br />
                <p>
                  Ensuring data security and privacy is paramount in deploying
                  Generative AI solutions. At Incede, we employ advanced
                  encryption, access control mechanisms, and data anonymization
                  techniques to protect sensitive information throughout the AI
                  lifecycle. Our solutions adhere to global and
                  industry-specific data privacy regulations such as GDPR, CCPA,
                  and HIPAA, minimizing legal risks and ensuring compliance.
                  Regular data audits, continuous monitoring, and secure
                  development practices are integrated into our processes to
                  detect and address potential vulnerabilities promptly,
                  ensuring the integrity and reliability of AI models.
                </p>
                <br />
                <p>
                  We also prioritize user privacy through clear consent
                  protocols and data minimization principles, limiting data
                  collection to necessary information only. Our incident
                  response plans are robust, including protocols for
                  communication, containment, and remediation in case of data
                  breaches or security incidents. Additionally, we maintain
                  strong data backup and recovery mechanisms to ensure business
                  continuity. Continuous education and training programs for
                  employees and stakeholders further reinforce our commitment to
                  data security and privacy, building confidence and trust in
                  our Generative AI solutions.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default DataSecurityAndPrivacy;
